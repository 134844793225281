import { Button, Card, Drawer, Form, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
// import pdfFile from "../../Documents/Paytrend_static_report_demo.pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import CheckoutComponent from "../../Payment/checkout";
import { frontendUrl } from "../../Config/config";
import LogoComponent from "../LogoComponent";
import { useNavigate } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";
import BlogList from "../Blog/blogList";
import blogContent from "../../Config/blogContent.json";

// const pdfFile = require("../../Documents/Paytrend_static_report_demo.pdf");

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const roles = [
  { role: "Data Scientist", price: "₹750" },

  { role: "UX/UI Designer", price: "₹750" },
  { role: "Architect", price: "₹750" },
  { role: "Automation Engineer", price: "₹750" },
  { role: "Back End Developer", price: "₹750" },
  { role: "Data Analyst", price: "₹750" },

  { role: "Data Science & Analytics", price: "₹750" },
  { role: "Database Administrator", price: "₹750" },

  { role: "Front End Developer", price: "₹750" },
  { role: "Full Stack Developer", price: "₹750" },

  { role: "Quality Assurance", price: "₹750" },

  { role: "Software Developer", price: "₹750" },
  { role: "Software Engineer", price: "₹750" },

  { role: "Technical Architect", price: "₹750" },
];

const StaticReportsStore = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const [userSubmitted, setUserSubmitted] = useState(true);
  const [actionType, setActionType] = useState(""); // State to track the action type

  const [filteredRoles, setFilteredRoles] = useState(roles);

  const handleInputChange = (event: any) => {
    const value = event.target.value;

    const filteredRolesData = roles.filter((role: any) =>
      role.role.toLowerCase().includes(value?.toLowerCase())
    );
    setFilteredRoles(filteredRolesData);
  };

  function formatProductName(productName: string) {
    return productName
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-") // Replace any non-alphanumeric characters with hyphens
      .replace(/^-+|-+$/g, ""); // Remove leading or trailing hyphens
  }

  useEffect(() => {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      setUserSubmitted(true);
    }
  }, []);

  const handleViewDemo = () => {
    setActionType("view"); // Set action type to "view"
    if (userSubmitted) {
      setDrawerVisible(true);
    } else {
      setModalVisible(true);
    }
  };

  const handleDownloadReport = () => {
    // setActionType("download"); // Set action type to "download"
    // if (userSubmitted) {
    //   downloadReport();
    // } else {
    //   setModalVisible(true);
    // }
    downloadReport();
  };

  const handleFormSubmit = (values: any) => {
    localStorage.setItem("userDetails", JSON.stringify(values));
    setUserSubmitted(true);
    setModalVisible(false);

    // Perform the action based on the actionType
    if (actionType === "view") {
      setDrawerVisible(true);
    } else if (actionType === "download") {
      downloadReport();
    }
  };

  const downloadReport = () => {
    // Logic to download the report
    const link = document.createElement("a");
    link.href = `${frontendUrl}/report.pdf`;
    link.download = "Paytrend_static_report_demo.pdf";
    link.click();
  };

  const downloadSalaryReport = (product: string) => {
    const productName = formatProductName(product);
    // Logic to download the report

    window.open(`/${productName}.pdf`);
  };

  const pagesArr = [1, 2, 3];
  const drawerContent = pagesArr.map((page) => (
    <div key={page}>
      <Document file={"http://localhost:3000/report.pdf"}>
        <Page pageNumber={page} />
      </Document>
    </div>
  ));
  const navigate = useNavigate();
  return (
    <div>
      <div className=" border-bottom py-3">
        <div className=" d-flex justify-content-between container">
          <h1
            className="m-0 text-primary"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
          >
            Paytrend
          </h1>
          <LogoComponent />
        </div>
      </div>
      <div className="container">
        <div className="py-5">
          <div>
            <p className="static-report-header-top">India</p>
            <h1 className="static-report-header">Static Reports 2024</h1>
          </div>
          <div className="mt-4">
            <p className="static-report-description">
              Unlock the power of data with our comprehensive Static Reports for
              2024. Get detailed insights into salaries, skill trends, and
              industry comparisons across various roles. Perfect for HR
              professionals, hiring managers, and industry analysts looking to
              make informed decisions.
            </p>
            <div className="d-flex justify-content-center align-items-center mt-5 flex-lg-row flex-column ">
              <Button
                className="btn-primary mr-lg-3 mr-0 mb-3 mb-lg-0"
                size="large"
                onClick={handleDownloadReport}
              >
                Download Demo Report
              </Button>
              <Button
                onClick={() => navigate("/paytrend")}
                className="btn-secondary"
                size="large"
              >
                Access online tool
              </Button>
            </div>
          </div>
        </div>

        <div className="py-5">
          <h2 className="static-report-subheader">
            What's inside the reports?
          </h2>
          <div className="mt-5">
            <div className="d-lg-flex flex-row-reverse ">
              <div className="col-lg-6 d-flex justify-content-center mb-5 mb-lg-0">
                <img
                  src="https://res.cloudinary.com/dbgw9jgum/image/upload/v1723534889/Screenshot_2024-08-13_at_1.09.42_PM_vjditg.png"
                  alt="Job Description Insights"
                  width={300}
                  className="border"
                />
              </div>
              <div className="static-report-list mt-3 col-lg-6">
                <div className="d-flex align-items-lg-center align-items-start mb-5">
                  <span className="text-primary list-number-2 mr-3">✓</span>{" "}
                  <p className="m-0 static-report-list-item ">
                    In-depth salary data across multiple roles and industries.
                  </p>
                </div>
                <div className="d-flex align-items-lg-center align-items-start mb-5">
                  <span className="text-primary list-number-2 mr-3">✓</span>{" "}
                  <p className="m-0 static-report-list-item ">
                    Detailed skill trends and analysis for key job roles.
                  </p>
                </div>
                <div className="d-flex align-items-lg-center align-items-start mb-5">
                  <span className="text-primary list-number-2 mr-3">✓</span>{" "}
                  <p className="m-0 static-report-list-item ">
                    Industry comparisons to understand where each role stands.
                  </p>
                </div>
                <div className="d-flex align-items-lg-center align-items-start mb-5">
                  <span className="text-primary list-number-2 mr-3">✓</span>{" "}
                  <p className="m-0 static-report-list-item ">
                    Experience-based salary breakdowns.
                  </p>
                </div>
                <div className="d-flex align-items-lg-center align-items-start mb-5">
                  <span className="text-primary list-number-2 mr-3">✓</span>{" "}
                  <p className="m-0 static-report-list-item ">
                    Customized insights for strategic decision-making.
                  </p>
                </div>
                <p className="d-none">
                  Here you can see the example of{" "}
                  <span
                    className="text-primary "
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={handleViewDemo}
                  >
                    Salary Report 2024
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="py-5">
          <h2 className="static-report-subheader mb-5">
            Static Salary Reports
          </h2>
          <h3 style={{ fontSize: "24px" }} className="text-center mb-5">
            Anyone who downloads the report is personally liable for not
            distributing it to others. By downloading the report, you agree not
            to share any part of it.
          </h3>
          <div className="d-none justify-content-center mb-5 ">
            <Input
              className="primary-input col-lg-6"
              placeholder="Search a role"
              onChange={handleInputChange}
              style={{ width: "100%", marginBottom: "20px" }}
            />
          </div>

          <div className="row justify-content-start">
            {filteredRoles.map((item, index) => (
              <div className="col-lg-3 col-md-6 mb-4" key={index}>
                <Card bordered={true} className="text-center">
                  <img
                    alt={item.role}
                    width={100}
                    src={`https://res.cloudinary.com/dbgw9jgum/image/upload/v1723576142/pdf_xtavkh.png`}
                    className="mb-3"
                  />
                  <h3 className="m-0" style={{ fontWeight: "normal" }}>
                    {item.role}
                  </h3>
                  {/* <h3>₹500</h3> */}
                  {/* <h3 className="static-report-price mb-3">
                    <span className="text-muted price-strike mr-2">
                      {item.price}
                    </span>
                    <span
                      className="text-success"
                      style={{ fontSize: "12px", color: "green" }}
                    >
                      33% Off
                    </span>
                  </h3> */}

                  <Button
                    className="btn-primary mt-3"
                    onClick={() => downloadSalaryReport(item.role)}
                  >
                    Download
                  </Button>

                  {/*                   
                  <CheckoutComponent
                    price={500}
                    subject={"Placing Order"}
                    description={`${item.role} Salary Report`}
                    text={"Buy now"}
                    currency="INR"
                    addressId={""}
                    product={item.role}
                  /> */}
                </Card>
              </div>
            ))}
          </div>
        </div>

        <section className="py-5 ">
          <div className="container">
            <div className="section-tittle text-center mb-20  ">
              <h2 className="static-report-subheader mb-5">Blogs</h2>
            </div>
            <div className="">
              <div className="d-flex justify-content-end">
                <p className="text-primary" onClick={() => navigate("/blog")}>
                  See More <RightOutlined />
                </p>
              </div>

              <BlogList blogs={blogContent} limit={3} />
            </div>
          </div>
        </section>

        <Drawer
          title="Salary Report Example"
          width={720}
          onClose={() => setDrawerVisible(false)}
          visible={drawerVisible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <div>
            {/* <div className="d-flex justify-content-end">
            <Button
              className="btn-primary mr-lg-3 mr-0"
              size="large"
              onClick={handleDownloadReport}
            >
              Download
            </Button>
          </div> */}

            {drawerContent}
          </div>
        </Drawer>

        <Modal
          title="Submit Your Details"
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          footer={null}
        >
          <Form layout="vertical" onFinish={handleFormSubmit}>
            <Form.Item
              name="name"
              rules={[{ required: true, message: "Please enter your name" }]}
            >
              <Input placeholder="Name" className="primary-input" />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Please enter your email" }]}
            >
              <Input
                type="email"
                placeholder="Email"
                className="primary-input"
              />
            </Form.Item>
            <Button className="btn-primary" size="large" htmlType="submit">
              Submit
            </Button>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default StaticReportsStore;
